/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
// import Button from "../shared/button";
import Social, { SocialLink } from "../../components/shared/social/index";
import {
    HeaderTop,
    NoticeText,
    HeaderTopRight,
    HeaderTopLeft,
    CallToAction,
    SelectLanguage,
    FormSelect,
    FormSelectList,
    NavItem,
} from "./style";
const HeaderTopArea = () => {
    return (
        <HeaderTop>
            <Container>
                <Row style={{ justifyContent: "space-between" }}>
                    <Col lg={4} md={4} sm={6} xs={4}>
                        <HeaderTopLeft>
                            <div className='headerTopLeft'>
                                <span
                                    className='text1'
                                    style={{ fontFamily: "Kalpurush" }}
                                >
                                    আমাদের সেবা এবং পরিকল্পসমূহ
                                </span>
                                <span
                                    className='text2'
                                    style={{ fontFamily: "Kalpurush" }}
                                >
                                    সম্পর্কে জানতে ডায়াল করুনঃ
                                </span>
                                <span className='text3'>
                                    ০ ১ ৯ ৮ ৮ ০ ০ ০ ৫ ৫ ৫
                                </span>
                            </div>
                        </HeaderTopLeft>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={4}>
                        <HeaderTopRight>
                            <div className='headerTopRight'>
                                <i
                                    className='icon icofont-phone'
                                    style={{
                                        color: "#fff",
                                    }}
                                ></i>
                                <span className='numBox'>
                                    <span className='num1'>
                                        +88-02-8878836-44
                                    </span>
                                    <span className='num2'>
                                        +88-013-21202220 -23
                                    </span>
                                </span>
                            </div>
                        </HeaderTopRight>
                    </Col>
                </Row>
            </Container>
        </HeaderTop>
    );
};

export default HeaderTopArea;
