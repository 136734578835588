/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import HeaderTopArea from "../../components/header-top";
import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import Logo from "../../components/logo";
import { Link } from "gatsby";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MainMenu from "../../components/menu/main-menu";
// import Button from "../../components/shared/button";
import MobileNavMenu from "../../components/menu/mobile-menu";
// import { StaticImage } from "gatsby-plugin-image";
import { useSticky } from "../../hooks";
import {
    HeaderWrap,
    HeaderBottomArea,
    MainmenuWrap,
    Menu,
    MobileMenuBtn,
    LogoImg,
} from "./style";

const Header = ({ data }) => {
    // Sticky Header
    const { sticky, headerRef, fixedRef } = useSticky();

    // OfCanvas Menu
    const [ofcanvasOpen, setOfcanvasOpen] = useState(false);

    // OfCanvas Menu Open & Remove
    const ofcanvasHandaler = () => {
        setOfcanvasOpen(prev => !prev);
    };

    return (
        <HeaderWrap ref={headerRef}>
            <HeaderTopArea />
            <HeaderBottomArea ref={fixedRef} isSticky={sticky}>
                <Container>
                    <MainmenuWrap>
                        <Logo />
                        <Menu>
                            <MainMenu allmenuData={data.menu} />
                            <LogoImg>
                                <div className='headerBottomRight'>
                                    <div className='textBox'>
                                        <span className='text1'>
                                            Credit Rating
                                        </span>
                                        <span className='text2'>
                                            Rated by CRISL
                                        </span>
                                    </div>
                                    <img
                                        className='text3'
                                        src='https://reliance.com.bd/img/img/home%20page%20new%20slide%20next.v/AAA.png'
                                        alt='1'
                                    />
                                </div>
                            </LogoImg>
                            <MobileMenuBtn
                                onClick={ofcanvasHandaler}
                                onKeyDown={ofcanvasHandaler}
                            >
                                <span className='line'></span>
                                <span className='line'></span>
                                <span className='line'></span>
                            </MobileMenuBtn>
                            <MobileNavMenu
                                MobilemenuData={data.menu}
                                ofcanvasOpen={ofcanvasOpen}
                                ofcanvasHandaler={ofcanvasHandaler}
                            />
                        </Menu>
                    </MainmenuWrap>
                </Container>
            </HeaderBottomArea>

            <div className='onlineProduct'>
                <Link to='/products/fire-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Fire Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Fireout.png'
                            alt='1'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/motor-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Motor Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Motor%20Out.png'
                            alt='2'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/marine-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Marine Cargo Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Marine%20Cargo%20Out.png'
                            alt='3'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/marine-hull-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Marine Hull Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Marine%20Hull%20Out.png'
                            alt='4'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/engineering-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Engineering Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/EngOut.png'
                            alt='5'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/liability-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Liability Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/LIability1.png'
                            alt='6'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/health-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Health Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Health%20Out.png'
                            alt='7'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='/products/miscellaneous-insurance'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Miscellaneous Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product%20logo%20next/Misc%20Out.png'
                            alt='8'
                        />
                    </OverlayTrigger>
                </Link>
                <Link to='http://apps.reliance.com.bd/'>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='tooltip-bottom'>
                                Online Insurance
                            </Tooltip>
                        }
                    >
                        <img
                            className='onlineProductLogo'
                            src='https://reliance.com.bd/img/img/product-logo/Cart.png'
                            alt='9'
                        />
                    </OverlayTrigger>
                </Link>
            </div>
        </HeaderWrap>
    );
};

Header.propTypes = {
    data: PropTypes.shape({
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default Header;
