import styled, { css, device, themeGet } from "@theme/utils";

export const HeaderWrap = styled.header`
    position: relative;
    .onlineProduct {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: 1px solid #602090;
        // padding: 5px;
    }
    .onlineProductLogo {
        max-width: 50px;
        max-height: 50px;
        padding: 3px;
        border: 2px solid #602090;
        border-radius: 5px;
        margin: 5px 20px;
    }
    @media only screen and (max-width: 800px) {
        .onlineProductLogo {
            max-width: 30px;
            max-height: 30px;
            margin: 2px 5px;
        }
    }
    @media only screen and (max-width: 355px) {
        .onlineProductLogo {
            max-width: 25px;
            max-height: 25px;
            margin: 2px 4px;
        }
    }
`;
export const HeaderBottomArea = styled.div`
    position: relative;
    ${({ isSticky }) =>
        isSticky &&
        css`
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 999;
            background: #fff;
            z-index: 99;
            box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        `};
    .headerBottomRight {
        display: flex;
        flex-direction: row;
        height: 50px;
        align-items: center;
        // border: 2px solid #626135;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .textBox {
        display: flex;
        flex-direction: column;
        margin-left: 6px;
    }
    .text1 {
        font-size: 18px;
        color: #602090;
        margin-top: 0px;
        margin-bottom: -9px;
    }
    .text2 {
        font-size: 18px;
        color: #602090;
        margin-top: -9px;
        margin-bottom: 0px;
    }
    .text3 {
        height: 60px;
        width: 60px;
        margin-left: 5px;
        margin-right: 6px;
    }
`;
export const MainmenuWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
`;
export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
// Mobile Menu Icon UI
export const MobileMenuBtn = styled.button`
    border: none;
    text-align: center;
    background: ${themeGet("colors.gradient")};
    padding: 0px !important;
    width: 50px;
    height: 50px;
    z-index: 3;
    margin-left: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${device.xlarge} {
        display: none;
    }
    @media only screen and (max-width: 1300px) {
        display: flex;
    }
    .line {
        position: relative;
        display: block;
        width: 25px;
        height: 2px;
        background: #fff;
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s;
        & + .line {
            margin-top: 4px;
        }
    }
    &:hover {
        .line {
            &:nth-of-type(1) {
                transform: rotate(45deg);
                top: 7px;
            }
            &:nth-of-type(2) {
                opacity: 0;
                visibility: hidden;
            }
            &:nth-of-type(3) {
                transform: rotate(-46deg);
                bottom: 5px;
            }
        }
    }
`;
export const LogoImg = styled.div`
    padding: 10px 0;
    @media only screen and (max-width: 1300px) {
        display: none;
    }
`;
