import styled from "@theme/utils";
export const ServiceMenuWidgetBox = styled.div``;
export const ServiceUl = styled.ul``;
export const NavList = styled.li`
    line-height: 0;
`;
export const NavLink = styled.a`
    // font-weight: 100;
    line-height: 2;
    color: #ffffff;
    font-size: 14px;
    & i {
        margin-right: 10px;
    }
`;
