import styled, { device, themeGet } from "@theme/utils";
import slelectimg from "@data/images/icons/arrow-down-white.png";

export const HeaderTop = styled.div`
    background-color: #602090;
    justify-content: space-between;
`;

export const HeaderLeft = styled.div`
    background-color: #000;
`;
export const NoticeText = styled.p`
    background: ${themeGet("colors.gradient")};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    ${device.large} {
        padding-left: 60px;
        &:after {
            position: absolute;
            content: "";
            left: 0;
            height: 0px;
            width: 0px;
            background: ${themeGet("colors.gradient")};
            top: 50%;
        }
    }
    ${device.xlarge} {
        padding-left: 120px;
        &:after {
            width: 90px;
        }
    }
`;

export const HeaderTopRight = styled.div`
    display: flex;
    align-items: right;
    justify-content: flex-end;
    color: #fff;
    .headerTopRight {
        display: flex;
        width: 245px;
        color: #fff;
        margin-bottom: 3px;
        margin-top: 0px;
        margin-right: -15px;
    }
    .icon {
        font-size: 38px;
        font-weight: normal;
        background-color: #602090;
        padding: 15px 5px 0px 18px;
    }
    .numBox {
        display: flex;
        flex-direction: column;
    }
    .num1 {
        font-size: 16px;
        padding: 0px 5px;
        margin-bottom: -4px;
        margin-top: 4px;
    }
    .num2 {
        font-size: 16px;
        padding: 0px 5px;
        margin-top: -5px;
    }
    @media only screen and (max-width: 1023px) {
        .headerTopRight {
            width: 245px;
            color: #fff;
            margin-bottom: 2px;
            margin-top: -1px;
            margin-right: -55px;
        }
        .icon {
            font-size: 32px;
            font-weight: normal;
            padding: 10px 0px 0px 30px;
        }
        .numBox {
            display: flex;
            flex-direction: column;
            margin-left: 3px;
            margin-top: -2px;
        }
        .num1 {
            font-size: 13px;
            margin-bottom: -4px;
            margin-top: 5px;
        }
        .num2 {
            font-size: 13px;
            margin-top: -5px;
        }
    }
    @media only screen and (max-width: 580px) {
        .headerTopRight {
            color: #fff;
            margin-bottom: 3px;
            margin-top: 1px;
            margin-right: -130px;
        }
        .icon {
            font-size: 25px;
            font-weight: normal;
            padding: 4px 0px 0px 30px;
        }
        .numBox {
            display: flex;
            flex-direction: column;
            // align-items: center;
            margin-left: 3px;
        }
        .num1 {
            font-size: 8px;
            padding: 0px 2px;
            // margin-bottom: -5px;
            margin-top: 2px;
        }
        .num2 {
            font-size: 8px;
            padding: 0px 2px;
            margin-top: 1px;
        }
    }
    @media only screen and (max-width: 480px) {
        .headerTopRight {
            color: #fff;
            margin-bottom: 2px;
            margin-top: 1px;
            margin-right: -130px;
        }
        .icon {
            font-size: 25px;
            font-weight: normal;
            padding: 6px 0px 0px 15px;
        }
        .numBox {
            display: flex;
            flex-direction: column;
            margin-top: -1px;
            margin-left: 3px;
        }
        .num1 {
            font-size: 8px;
            padding: 0px 2px;
            margin-top: 3px;
        }
        .num2 {
            font-size: 8px;
            padding: 0px 2px;
            margin-top: 2px;
        }
    }
    @media only screen and (max-width: 370px) {
        .headerTopRight {
            color: #fff;
            margin-bottom: 1px;
            margin-top: 1px;
            margin-right: -145px;
        }
        .icon {
            font-size: 20px;
            font-weight: normal;
            padding: 4px 0px 0px 2px;
        }
        .numBox {
            display: flex;
            flex-direction: column;
            margin-left: 2px;
        }
        .num1 {
            font-size: 7px;
            padding: 0px 2px;
            margin-top: 2px;
        }
        .num2 {
            font-size: 7px;
            padding: 0px 2px;
            margin-top: 1px;
        }
    }
`;
export const HeaderTopLeft = styled.div`
    display: flex;
    align-items: left;
    justify-content: flex-start;
    color: #fff;
    .headerTopLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 245px;
        color: #fff;
        margin-bottom: -6px;
        margin-top: 1px;
        margin-left: -28px;
    }
    .text1 {
        font-size: 14px;
        font-weight: bold;
        padding: 0px 0px;
        margin-top: -3px;
        margin-bottom: -5px;
    }
    .text2 {
        font-size: 15px;
        font-weight: bold;
        margin-top: -6px;
    }
    .text3 {
        // background-color: #fff;
        // border: 1px solid #000;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        padding: 0px 10px;
        margin-top: -14px;
    }
    @media only screen and (max-width: 1023px) {
        .headerTopLeft {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 245px;
            color: #fff;
            margin-bottom: -6px;
            margin-top: 1px;
            margin-left: -55px;
        }
        .text1 {
            font-size: 11px;
            font-weight: bold;
            padding: 0px 0px;
            margin-top: -3px;
            margin-bottom: -5px;
        }
        .text2 {
            font-size: 12px;
            font-weight: bold;
            margin-top: -3px;
        }
        .text3 {
            font-size: 14px;
            font-weight: bold;
            padding: 0px 10px;
            margin-top: -11px;
        }
    }
    @media only screen and (max-width: 580px) {
        .headerTopLeft {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            margin-bottom: -6px;
            margin-top: 1px;
        }
        .text1 {
            font-size: 8px;
            font-weight: bold;
            padding: 0px 0px;
            margin-top: -1px;
            margin-bottom: 1px;
        }
        .text2 {
            font-size: 8px;
            font-weight: bold;
            margin-top: -6px;
        }
        .text3 {
            font-size: 9px;
            font-weight: bold;
            padding: 0px 10px;
            margin-top: -7px;
        }
    }
    @media only screen and (max-width: 480px) {
        .headerTopLeft {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            // margin-bottom: -6px;
            margin-top: 4px;
        }
        .text1 {
            font-size: 6px;
            font-weight: bold;
            padding: 0px 0px;
        }
        .text2 {
            font-size: 7px;
            font-weight: bold;
            margin-top: -5px;
        }
        .text3 {
            font-size: 8px;
            font-weight: bold;
            padding: 0px 10px;
            margin-top: -5px;
        }
    }
    @media only screen and (max-width: 370px) {
        .headerTopLeft {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            // margin-bottom: -6px;
            margin-top: 4px;
            margin-left: -25px;
        }
        .text1 {
            font-size: 5px;
            font-weight: bold;
            padding: 0px 0px;
        }
        .text2 {
            font-size: 6px;
            font-weight: bold;
            margin-top: -5px;
        }
        .text3 {
            font-size: 7px;
            font-weight: bold;
            padding: 0px 10px;
            margin-top: -6px;
        }
    }
`;

export const CallToAction = styled.div`
    display: none;
    ${device.small} {
        display: block;
    }
    a {
        color: #fff;
        &:hover {
            background: ${themeGet("colors.gradient")};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`;

export const SelectLanguage = styled.div``;

export const FormSelect = styled.ul`
    background: transparent url(${slelectimg}) no-repeat center right 0px;
    & > li {
        padding: 0 20px;
    }
    li:hover {
        & .language-ul {
            opacity: 1;
            visibility: visible;
            top: 100%;
        }
    }
    a {
        padding: 5px 0px;
        display: block;
        font-weight: 400;
        color: #fff;
    }
`;

export const FormSelectList = styled.ul`
    background: #fff;
    width: -webkit-fill-available;
    &.language-ul {
        opacity: 0;
        visibility: hidden;
        // opacity: 1;
        // visibility: visible;
        position: absolute;
        top: 100%;
        width: 100px;
        z-index: 3;
        right: 0;
    }
    li > a {
        padding: 3px 5px;
        display: block;
        color: ${themeGet("colors.primary")};
        &:hover {
            background: ${themeGet("colors.gradient")};
            color: #fff;
        }
    }
`;

export const NavItem = styled.li`
    position: relative;
`;
