import React from "react";
import { Global, css, device, useTheme } from "@theme/utils";
import slelectimg from "@data/images/icons/arrow-down-white.png";
export const GlobalCSS = () => {
    const theme = useTheme();
    return (
        <Global
            styles={css`
                @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");

                *,
                *::before,
                *::after {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                html {
                    -webkit-text-size-adjust: 100%;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    scroll-behavior: smooth;
                    // Default
                    height: 100%;
                    font-size: 70.5%; // 1rem = 6px

                    // Medium Device >=768
                    ${device.medium} {
                        font-size: 73.75%; // 1rem = 7px
                    }

                    // Large Device >=992
                    ${device.large} {
                        font-size: 50%; // 1rem = 8px
                    }

                    // Extra Large Device >=1200
                    ${device.xlarge} {
                        font-size: 56.25%; // 1rem = 9px
                    }

                    // Extra Large Device >=1367
                    ${device.xxlarge} {
                        font-size: 82.5%; // 1rem = 10px
                    }
                }
                article,
                aside,
                details,
                figcaption,
                figure,
                footer,
                header,
                nav,
                section,
                summary {
                    display: block;
                }
                audio,
                canvas,
                video {
                    display: inline-block;
                }
                audio:not([controls]) {
                    display: none;
                    height: 0;
                }
                [hidden] {
                    display: none;
                }
                html,
                button,
                input,
                select,
                textarea {
                    font-family: "Source Sans Pro", Helvetica, sans-serif;
                }
                a:focus {
                    outline: thin dotted;
                }
                a:active,
                a:hover {
                    outline: 0;
                }
                b,
                strong {
                    font-weight: bold;
                }
                blockquote,
                q {
                    -webkit-hyphens: none;
                    -moz-hyphens: none;
                    -ms-hyphens: none;
                    hyphens: none;
                    quotes: none;
                }
                small {
                    font-size: smaller;
                }
                sub,
                sup {
                    font-size: 75%;
                    line-height: 0;
                    position: relative;
                    vertical-align: baseline;
                }
                sup {
                    top: -0.5em;
                }
                sub {
                    bottom: -0.25em;
                }
                li > ul,
                li > ol {
                    margin: 0;
                }
                img {
                    -ms-interpolation-mode: bicubic;
                    border: 0;
                    vertical-align: middle;
                }
                svg:not(:root) {
                    overflow: hidden;
                }
                figure {
                    margin: 0;
                }
                form {
                    margin: 0;
                }
                button,
                input,
                select,
                textarea {
                    font-size: 100%;
                    margin: 0;
                    max-width: 100%;
                    vertical-align: baseline;
                }

                button,
                input {
                    line-height: normal;
                }

                button,
                html input[type="button"],
                input[type="reset"],
                input[type="submit"] {
                    -webkit-appearance: button;
                    -moz-appearance: button;
                    appearance: button;
                    cursor: pointer;
                }

                button[disabled],
                input[disabled] {
                    cursor: default;
                }

                input[type="checkbox"],
                input[type="radio"] {
                    padding: 0;
                }

                input[type="search"] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    appearance: textfield;
                    padding-right: 2px;
                    width: 100%;
                }

                input[type="search"]::-webkit-search-decoration {
                    -webkit-appearance: none;
                    appearance: none;
                }

                button::-moz-focus-inner,
                input::-moz-focus-inner {
                    border: 0;
                    padding: 0;
                }
                textarea {
                    overflow: auto;
                    vertical-align: top;
                }
                * {
                    box-sizing: border-box;
                }
                img {
                    max-width: 100%;
                }
                html {
                    overflow: hidden;
                    overflow-y: auto;
                }
                body {
                    overflow: hidden;
                    margin: 0;
                    padding: 0;
                    line-height: ${theme.lineHeights.body};
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-family: ${theme.fonts.body};
                    font-weight: ${theme.fontWeights.body};
                    color: ${theme.colors.text};
                    font-size: ${theme.fontSize.body};
                    background: ${theme.colors.background};
                }
                a {
                    transition: ${theme.transition};
                    color: ${theme.colors.text};
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                        color: ${theme.colors.hover};
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                .h1,
                .h2,
                .h3,
                .h4,
                .h5,
                .h6 {
                    font-family: ${theme.fonts.heading};
                    line-height: ${theme.lineHeights.heading};
                    color: ${theme.colors.heading};
                    font-weight: ${theme.fontWeights.heading};
                    margin: 0;
                    margin-bottom: 8px;
                }
                h1,
                .h1 {
                    font-size: clamp(32px, 4.1vw, 75px);
                }
                h2,
                .h2 {
                    font-size: clamp(26px, 4.1vw, 55px);
                }
                h3,
                .h3 {
                    font-size: clamp(22px, 2.1vw, 30px);
                }
                h4,
                .h4 {
                    font-size: clamp(20px, 2.1vw, 25px);
                }
                h5,
                .h5 {
                    font-size: clamp(18px, 2.1vw, 22px);
                }
                h6,
                .h6 {
                    font-size: clamp(16px, 3.1vw, 20px);
                }
                p {
                    margin: 0 0 15px;
                    font-family: ${theme.fonts.body};
                    color: ${theme.colors.text};
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:only-child {
                        margin-bottom: 0;
                    }
                }
                input,
                button,
                select,
                textarea {
                    background: transparent;
                    border: 1px solid ${theme.colors.boder};
                    transition: ${theme.transition};
                    color: ${theme.colors.text};
                    &:focus,
                    &:active {
                        outline: none;
                        border-color: ${theme.colors.primary};
                    }
                }
                input,
                select,
                textarea {
                    width: 100%;
                }
                input,
                select {
                    height: 40px;
                    padding: 0 15px;
                }
                .form-select {
                    padding: 0px 20px 0 10px;
                    font-size: 16px;
                    max-width: 100%;
                    width: 100%;
                    outline: none;
                    border: none;
                    color: #333;
                    border-radius: 5px;
                    // background: transparent url(${slelectimg}) no-repeat center
                    //     right 5px;
                    background-color: #ddd;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                .wrapper {
                    position: relative;
                }
                .container {
                    max-width: 100%;
                    padding-right: var(--bs-gutter-x, 2.5rem);
                    padding-left: var(--bs-gutter-x, 2.5rem);
                    ${device.medium} {
                        padding-right: var(--bs-gutter-x, 0.9375rem);
                        padding-left: var(--bs-gutter-x, 0.9375rem);
                        max-width: 1024px;
                        &.container-max {
                            max-width: 100%;
                            // padding-top: 30px;
                        }
                        &.container-max-m {
                            max-width: 1024px;
                            padding: 10px;
                        }
                        .container-max-lg {
                            max-width: 100%;
                        }
                    }
                    ${device.large} {
                        max-width: 1440px;
                        &.container-max {
                            max-width: 1370px;
                        }
                        &.container-max-m {
                            max-width: 1320px;
                            padding: 10px;
                        }
                        &.container-max-lg {
                            max-width: 1500px;
                        }
                    }
                    ${device.xlarge} {
                        max-width: 1440px;
                    }
                    @media (min-width: 1600px) {
                        &.container-max {
                            max-width: 1370px;
                        }
                    }
                }

                .link-overlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    text-indent: -99999px;
                }
                @keyframes start-animation {
                    0%   { right:0px; opacity: 1;}
                    50%  { right:20px; opacity: .5;}
                    100% { right:50px; opacity: 0;}
                }
                @keyframes end-animation {
                    0%   { right:50px; opacity: 0;}
                    50%  { right:20px; opacity: .5;}
                    100% { right:0px; opacity: 1;}
                }
                .carousel-item {
                    transition: transform 2s ease-in;
                }
                // .carousel-fade .active.carousel-item-start,
                // .carousel-fade .active.carousel-item-end {
                //     // transition: opacity 0s 1s;
                //     position: relative;
                //     animation-name: example;
                //     animation-duration: 2s;
                // }
                // .carousel-item > .carousel-caption {
                //     transition: transform 1s ease-in;
                // }
                .carousel-fade .active.carousel-item-start > img {
                    transition: opacity 0s 1s;
                    position: relative;
                    animation-name: start-animation;
                    animation-duration: 2s;
                }
                .carousel-fade .active.carousel-item-end > img {
                    transition: opacity 0s 2s;
                    position: relative;
                    animation-name: end-animation;
                    animation-duration: 2s;
                }
                .carousel-image {
                    border-radius: 2px;
                    width: 700px;
                    height: 300px;
                    max-width: none;
                    margin: 2% auto 3% 13%;
                }
                .carousel-caption {
                    border-radius: 2px;
                    border: 1px solid #d3a68c;
                    background-color: #fff;
                    height: 170px;
                    left: 57%;
                    right: 13%;
                    bottom: 8%;
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .carousel-container {
                    max-width: 1430px;
                    padding: 20px;
                }
                .carousel-header {
                    font-size: 25px;
                    line-height: 18px;
                    color: #000;
                }
                .carousel-text {
                    color: #000;
                    line-height: 18px;
                }
                .carousel-button {
                    background-color: #602090;
                }
                @media only screen and (max-width: 1200px) {
                    .carousel-image {
                        border-radius: 2px;
                        width: 700px;
                        height: 300px;
                        max-width: none;
                        margin: 2% auto 5% 10%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        left: 65%;
                        right: 10%;
                        bottom: 10%;
                        text-align: left;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    .carousel-header {
                        font-size: 20px;
                    }
                    .carousel-text {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
                @media only screen and (max-width: 1023px) {
                    .carousel-image {
                        border-radius: 2px;
                        max-width: 600px;
                        margin: 2% auto 5% 10%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        // left: 57%;
                        right: 10%;
                        bottom: 12%;
                        text-align: left;
                        padding-left: 10px;
                        padding-right: 10px;
                        line-height: 12px;
                    }
                    .carousel-header {
                        font-size: 15px;
                        line-height: 14px;
                    }
                    .carousel-text {
                        font-size: 10px;
                        line-height: 12px;
                    }
                    .carousel-button {
                        font-size: 10px;
                    }
                }
                @media only screen and (max-width: 850px) {
                    .carousel-image {
                        border-radius: 2px;
                        max-width: 520px;
                        margin: 2% auto 8% 13%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 88px;
                        left: 57%;
                        right: 13%;
                        bottom: 15%;
                        text-align: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        line-height: 6px;
                    }
                    .carousel-header {
                        font-size: 10px;
                        line-height: 6px;
                        margin-top: -7px;
                    }
                    .carousel-text {
                        font-size: 8px;
                        line-height: 9px;
                    }
                    .carousel-button {
                        font-size: 6px;
                        padding: 3px;
                    }
                }
                @media only screen and (max-width: 800px) {
                    .carousel-image {
                        border-radius: 2px;
                        // max-width: 420px;
                        max-width: none;
                        width: 420px;
                        height: 220px;
                        margin: 2% auto 8% 13%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 90px;
                        left: 57%;
                        right: 13%;
                        bottom: 15%;
                        text-align: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        line-height: 6px;
                    }
                    .carousel-header {
                        font-size: 12px;
                        line-height: 6px;
                        margin-top: -7px;
                    }
                    .carousel-text {
                        font-size: 9px;
                        line-height: 9px;
                        margin-top: -5px;
                    }
                    .carousel-button {
                        font-size: 6px;
                        padding: 2px;
                    }
                }
                @media only screen and (max-width: 700px) {
                    .carousel-image {
                        border-radius: 2px;
                        // max-width: 320px;
                        max-width: none;
                        width: 320px;
                        height: 170px;
                        margin: 2% auto 8% 13%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 80px;
                        left: 57%;
                        right: 13%;
                        bottom: 15%;
                        text-align: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        line-height: 6px;
                    }
                    .carousel-header {
                        font-size: 9px;
                        line-height: 6px;
                        margin-top: -5px;
                    }
                    .carousel-text {
                        font-size: 6px;
                        line-height: 9px;
                        margin-top: -7px;
                    }
                    .carousel-button {
                        font-size: 6px;
                        padding: 3px;
                        margin-top: -7px;
                    }
                }
                @media only screen and (max-width: 560px) {
                    .carousel-image {
                        border-radius: 2px;
                        max-width: none;
                        width: 230px;
                        height: 120px;
                        margin: 2% auto 12% 13%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 60px;
                        left: 60%;
                        bottom: 25%;
                        text-align: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        line-height: 0px;
                    }
                    .carousel-header {
                        font-size: 7px;
                        line-height: 6px;
                        margin-top: -11px;
                        margin-bottom: 4px;
                    }
                    .carousel-text {
                        font-size: 5px;
                        line-height: 7px;
                        margin-top: -2px;
                        margin-bottom: 4px;
                    }
                    .carousel-button {
                        font-size: 5px;
                        padding: 2px;
                        margin-top: 1px;
                    }
                }
                @media only screen and (max-width: 430px) {
                    .carousel-image {
                        border-radius: 2px;
                        max-width: none;
                        width: 200px;
                        height: 100px;
                        // max-width: 150px;
                        margin: 2% auto 12% 11%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 55px;
                        left: 55%;
                        right: 12%;
                        bottom: 25%;
                        text-align: left;
                        padding-left: 5px;
                        padding-right: 5px;
                        line-height: 0px;
                    }
                    .carousel-header {
                        font-size: 7px;
                        line-height: 6px;
                        margin-top: -12px;
                        padding-bottom: 4px;
                    }
                    .carousel-text {
                        font-size: 5px;
                        margin-top: -4px;
                        line-height: 6px;
                    }
                    .carousel-button {
                        font-size: 4px;
                        padding: 1px;
                        margin: -1px 0px 0px;
                    }
                }
                @media only screen and (max-width: 370px) {
                    .carousel-image {
                        border-radius: 2px;
                        // max-width: 130px;
                        max-width: none;
                        width: 150px;
                        height: 90px;
                        margin: 2% auto 15% 14%;
                    }
                    .carousel-caption {
                        border-radius: 2px;
                        border: 1px solid #d3a68c;
                        background-color: #fff;
                        height: 52px;
                        left: 55%;
                        right: 14%;
                        bottom: 28%;
                        text-align: left;
                        padding: 2px;
                        line-height: 0px;
                    }
                    .carousel-header {
                        font-size: 6px;
                        line-height: 5px;
                        margin-top: 1px;
                        padding-bottom: 1px;
                    }
                    .carousel-text {
                        font-size: 5px;
                        line-height: 5px;
                        // padding-bottom: 4px;
                    }
                    .carousel-button {
                        font-size: 4px;
                        padding: 1px;
                        margin: -2px 0px 0px;
                    }
                }
                .popupImg {
                    max-width: none;
                    width: 798px;
                }
                @media only screen and (max-width: 992px) {
                    .popupImg {
                        width: 498px;
                    }
                }
                @media only screen and (max-width: 580px) {
                    .popupImg {
                        max-width: 100%;
                        // width: 550px;
                    }
                }
            `}
        />
    );
};
