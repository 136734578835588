import React, { useEffect } from "react";
const facebookAppId = "<PAGE_ID>";
export function CustomerChat () {
    useEffect(() => {
        window.fbAsyncInit = function () {
            FB.init({
                xfbml: true,
                version: "v13.0",
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src =
                "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    });
    return (
        <>
            <div id='fb-root' />
            <div
                className='fb-customerchat'
                attribution='install_email'
                attribution_version='biz_inbox'
                page_id='1124762907692303'
            ></div>
        </>
    );
}
